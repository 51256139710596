angular.module("app").service("helpers", function() {

  this.awsUploads = `https://s3-us-west-2.amazonaws.com/bassmollett-uploads/`

  this.imgixBase = `https://bassmollett.imgix.net/`

  this.imgixCase = `https://bassmollett-case.imgix.net/`

  this.imgixTheme = `https://bassmollett-theme.imgix.net/`

  this.imgixLogo = `https://bassmollett-logos.imgix.net/`

  this.imgixFlower = `https://bassmollett-floralpix.imgix.net/`

  this.squareFeather = `https://bassmollett.imgix.net/squarefeatherbox.png`

  this.circleFeather = `https://bassmollett.imgix.net/circlefeather.png`

  this.transparent = `https://bassmollett.imgix.net/transparent.png`

  this.fonts = [
    {
      name: "Amazone"
    },
    {
      name: "Antiqua"
    },
    {
      name: "Book Antiqua",
      weights: [
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Bookman Old Style",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Century Gothic",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Corsiva"
    },
    {
      name: "English"
    },
    {
      name: "FG Jayne"
    },
    {
      name: "Gaeilge"
    },
    {
      name: "Goudy Catalog"
    },
    {
      name: "Goudy Old Style",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Lato",
      weights: [
        {
          name: "Normal",
          weight: 400
        },
        {
          name: "Bold",
          weight: 900
        }
      ]
    },
    {
      name: "Liberty"
    },
    {
      name: "Mistral"
    },
    {
      name: "Monterey"
    },
    {
      name: "Murray Hill",
      weights: [
        {
          name: "Normal",
          weight: "Normal"
        },
        {
          name: "Bold",
          weight: "Bold"
        }
      ]
    },
    {
      name: "Nuptial"
    },
    {
      name: "Papyrus"
    },
    {
      name: "Park Avenue"
    },
    {
      name: "Piranesi"
    },
    {
      name: "Pristina"
    },
    {
      name: "Ribbon"
    },
    {
      name: "Ruthie"
    },
    {
      name: "Scriptina"
    },
    {
      name: "Shelley Allegro"
    },
    {
      name: "Shelley Andante"
    },
    {
      name: "Times New Roman",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Vivaldii"
    },
    {
      name: "Wedding Text"
    }
  ];

  this.colorOptions = {
    preferredFormat: 'hex',
    allowEmpty: true,
    replacerClassName: 'color-stage-trigger',
    showInput: true,
    showPalette: true,
    showButtons: false,
    showSelectionPalette: true,
    palette: [
        ["#000","#444","#666","#999","#ccc","#eee","#f3f3f3","#fff"],
        ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
        ["#f4cccc","#fce5cd","#fff2cc","#d9ead3","#d0e0e3","#cfe2f3","#d9d2e9","#ead1dc"],
        ["#ea9999","#f9cb9c","#ffe599","#b6d7a8","#a2c4c9","#9fc5e8","#b4a7d6","#d5a6bd"],
        ["#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6fa8dc","#8e7cc3","#c27ba0"],
        ["#c00","#e69138","#f1c232","#6aa84f","#45818e","#3d85c6","#674ea7","#a64d79"],
        ["#900","#b45f06","#bf9000","#38761d","#134f5c","#0b5394","#351c75","#741b47"],
        ["#600","#783f04","#7f6000","#274e13","#0c343d","#073763","#20124d","#4c1130"]
    ]
  };

});
