(function() {
  'use strict'
  angular.module('app').directive('themeCategoryDashboard', themeCategoryDashboardController)

  function themeCategoryDashboardController(themes, $q, $routeParams) {
    return {

      templateUrl: 'pages/themes/theme_category_dashboard/theme-category-dashboard.html',
      controllerAs: 'vm',
      controller: function($location) {
        var vm = this

        angular.extend(vm, {
          goToThemeEditor: goToThemeEditor,
          archiveTheme: archiveTheme,
          newThemePrompt: newThemePrompt
        })

        init()

        function init() {
          getCategoryThemes()
          getCategoryName()
        }

        function getCategoryThemes() {
          themes.getThemesByCategoryId($routeParams.id).then(response => {
            vm.themes = response.data
          })
        }

        function getCategoryName() {
          themes.getThemeCategoryById($routeParams.id).then(response => {
            vm.category = response.data
          })
        }

        function archiveTheme(id) {
          themes.archiveTheme(id).then(response => {
            getCategoryThemes()
          })
        }

        function newThemePrompt() {
          $('#newThemeModal').modal('show')
        }

        function goToThemeEditor(id) {
          if(id){
            $location.path(`themeEditor/${id}`)
          } else {
            const payload = {
              name: vm.newTheme.name,
              theme_category_id: vm.category.id,
              collection_id: vm.category.collection_id
            }
            themes.createTheme(payload).then(response => {
                goToThemeEditor(response.data.id)
            })
          }
        }

        //******** end *********//
      }
    }
  }

})();
