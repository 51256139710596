angular.module('app').service('analytics', function($http, $q) {

  this.themeUsage = null;
  this.productUsage = null;

	this.getThemesAnalytics = function() {
		var deferred = $q.defer();
		$http.get(api + "analytics/themesUsage").then(response => {
      this.themeUsage = response.data
      deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

  this.getProductsAnalytics = function() {
    var deferred = $q.defer();
    $http.get(api + "analytics/productsUsage").then(response => {
      this.productUsage = response.data
      deferred.resolve(response);
    }).catch(error => {
      deferred.resolve(error);
    });
    return deferred.promise;
  };

})
