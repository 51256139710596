angular.module('app').service('themes', function($http, $q) {

  // * * * * * * * * * * * COLLECTION ENDPOINTS * * * * * * * * * * * * * * * \\
  this.getCollectionById = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `collections/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  }

  this.getCollections = function() {
    const deferred = $q.defer();
    $http
      .get(api + 'collections')
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  // * * * * * * * * * * * CATEGORY ENDPOINTS * * * * * * * * * * * * * * * \\

  this.getThemeCategories = function() {
    const deferred = $q.defer();
    $http
      .get(api + `themeCategories`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getThemeCategoryById = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `themeCategories/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  }

  this.archiveCategory = function(id) {
    const deferred = $q.defer();
    $http
      .patch(api + `archiveThemeCategory/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.createCategory = function(payload) {
    const deferred = $q.defer();
    $http
      .post(api + 'createThemeCategory', payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  // * * * * * * * * * * * THEME ENDPOINTS * * * * * * * * * * * * * * * \\
  this.getThemesByCategoryId = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `themes?theme_category_id=${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getThemeById = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `themes/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.archiveTheme = function(id) {
    const deferred = $q.defer();
    $http
      .patch(api + `archiveTheme/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.createTheme = function(payload) {
    const deferred = $q.defer();
    $http
      .post(api + 'createTheme', payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateTheme = function(id, payload) {
    const deferred = $q.defer();
    $http
      .patch(api + `themes/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.uploadThemeImage = function(id, payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        "Content-Type": undefined
      }
    };
    $http
      .post(api + `uploadThemeImage/${id}`, payload, config)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  // * * * * * * * * * * * PRODUCT ENDPOINTS * * * * * * * * * * * * * * * \\
  this.getProductsByCollectionId = function(collection_id) {
    const deferred = $q.defer();
    $http
      .get(api + `products?collection_id=${collection_id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  }

  // * * * * * * * * * * * LAYOUT ENDPOINTS * * * * * * * * * * * * * * * \\
  this.getLayouts = function(product_id, theme_id) {
    const deferred = $q.defer();
    $http
      .get(api + `layoutsForCustomTheme?product_id=${product_id}&theme_id=${theme_id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.uploadReplacementImage = function(layout_id, payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        "Content-Type": undefined
      }
    };
    $http
      .post(api + `uploadReplacementImage/${layout_id}`, payload, config)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateThemeLayout = function(id, payload) {
    const deferred = $q.defer();
    $http
      .patch(api + `themeLayouts/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  // * * * * * * * * * * * WATERMARK ENDPOINTS * * * * * * * * * * * * * * * \\
  this.createWatermark = function(payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        "Content-Type": undefined
      }
    };
    $http
      .post(api + 'createWatermark', payload, config)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateWatermark = function(id, payload) {
    const deferred = $q.defer();
    $http
      .patch(api + `watermarks/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getWatermark = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `watermarks/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.archiveWatermark = function(id) {
    const deferred = $q.defer();
    $http
      .patch(api + `archiveWatermark/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

})
