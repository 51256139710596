(function() {
  'use strict'
  angular.module('app').directive('themesDashboard', themesDashboardController)

  function themesDashboardController(themes, $q) {
    return {

      templateUrl: 'pages/themes/themes_dashboard/themes-dashboard.html',
      controllerAs: 'vm',
      controller: function($location) {
        var vm = this

        angular.extend(vm, {
          themeCategories: [],
          archiveCategory: archiveCategory,
          newCategoryPrompt: newCategoryPrompt,
          createCategory: createCategory,
          goToCategoryDashboard: goToCategoryDashboard,
        })

        init()

        function init() {
          getThemeCategories()
          getCollections()
        }

        function getCollections() {
          themes.getCollections().then(response => {
            vm.themeCollections = response.data
          })
        }

        function getThemeCategories() {
          themes.getThemeCategories().then(response => {
            const sortedCats = response.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            vm.themeCategories = sortedCats
          })
        }

        function archiveCategory(id) {
          if(window.confirm('Are you sure you want to delete this category?')){
            themes.archiveCategory(id).then(() => {
              getThemeCategories()
            })
          }
        }

        function newCategoryPrompt() {
          $('#categoryModal').modal('show')
        }

        function createCategory() {
          !vm.newCategory || !vm.newCategory.name || !vm.newCategory.collection_id ?
            alert('Please choose and name and collection for your new category.') :
            themes.createCategory(vm.newCategory)
              .then(response => {
                getThemeCategories()
                $('#categoryModal').modal('hide')
                vm.newCategory = ''
              });
        }

        function goToCategoryDashboard(id, name) {
          $location.path(`category/${id}`);
        }

        //******** end *********//
      }
    }
  }

})();
