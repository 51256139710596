(function() {
  'use strict'
  angular.module('app').directive('login', loginController)

  function loginController(user, $location) {
    return {

      templateUrl: 'pages/login/login.html',
      controllerAs: 'vm',
      controller: function() {
        var vm = this

        angular.extend(vm, {
          form: {},
          login: login,
          waiting: false,
          error: null
        })

        function login() {
          vm.waiting = true
          user.login(vm.form).then(response => {
            if (response.data.error) {
              vm.error = response.data.message
              vm.waiting = false
            } else {
              if(response.data.type == 'superAdmin'){
                $location.path('clients')
              } else {
                $location.path('corporateClients')
              }
              vm.waiting = false
            }
          })
        }

        //******** end *********//
      }
    }
  }

})();
