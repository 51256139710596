"use strict";
/*******************************
 adapted off of weeroom/angularjs-imageupload-directive and JIC from github
 https://github.com/weroom/angularjs-imageupload-directive/blob/master/public/javascripts/imageupload.js
 https://github.com/brunobar79/J-I-C

 Customized by Paxton Bigler 2018
 *********************************/

angular.module("app").directive("image", [
  "$q",
  "themes",
  "$rootScope",
  function($q, themes, $rootScope) {
    var URL = window.URL || window.webkitURL;
    var getResizeArea = function() {
      var resizeAreaId = "fileupload-resize-area";

      var resizeArea = document.getElementById(resizeAreaId);

      if (!resizeArea) {
        resizeArea = document.createElement("canvas");
        resizeArea.id = resizeAreaId;
        resizeArea.style.visibility = "hidden";
        document.body.appendChild(resizeArea);
      }

      return resizeArea;
    };

    /**
     * Receives an Image Object (can be JPG OR PNG) and returns a new Image Object compressed
     * @param {Image} sourceImgObj The source Image Object
     * @param {Integer} quality The output quality of Image Object
     * @return {Image} result_image_obj The compressed Image Object
     */

    var jicCompress = function(sourceImgObj, options, imgSize) {
      var outputFormat = options.resizeType;
      var quality = 80;

      // if(imgSize < 3000000){
      // 	quality = 90
      // } else {
      // 	quality = options.resizeQuality * 100 || 70;
      // }

      var mimeType = "image/jpeg";
      if (outputFormat !== undefined && outputFormat === "png") {
        mimeType = "image/png";
      }

      var maxHeight = options.resizeMaxHeight || 300;
      var maxWidth = options.resizeMaxWidth || 250;

      var height = sourceImgObj.height;
      var width = sourceImgObj.width;

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      var cvs = document.createElement("canvas");
      cvs.width = width; //sourceImgObj.naturalWidth;
      cvs.height = height; //sourceImgObj.naturalHeight;
      var ctx = cvs
        .getContext("2d")
        .drawImage(sourceImgObj, 0, 0, width, height);
      var newImageData = cvs.toDataURL(mimeType, quality / 100);
      var resultImageObj = new Image();
      resultImageObj.src = newImageData;
      return resultImageObj.src;
    };

    var resizeImage = function(origImage, options) {
      var maxHeight = options.resizeMaxHeight || 300;
      var maxWidth = options.resizeMaxWidth || 250;
      var quality = options.resizeQuality || 0.7;
      var type = options.resizeType || "image/jpg";

      var canvas = getResizeArea();

      var height = origImage.height;
      var width = origImage.width;

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      //draw image on canvas
      var ctx = canvas.getContext("2d");
      ctx.drawImage(origImage, 0, 0, width, height);

      // get the data from canvas as 70% jpg (or specified type).
      return canvas.toDataURL(type, quality);
    };

    var createImage = function(url, callback) {
      var image = new Image();
      image.onload = function() {
        callback(image);
      };
      image.src = url;
    };

    var fileToDataURL = function(file) {
      var deferred = $q.defer();
      var reader = new FileReader();
      reader.onload = function(e) {
        deferred.resolve(e.target.result);
      };
      reader.readAsDataURL(file);
      return deferred.promise;
    };

    return {
      restrict: "AE",
      scope: {
        image: "=",
        resizeMaxHeight: "@?",
        resizeMaxWidth: "@?",
        resizeQuality: "@?",
        resizeType: "@?",
        tableId: "@?",
        src: "@?"
      },
      link: function postLink(scope, element, attrs, $parent) {
        var doResizing = function(imageResult, callback) {
          createImage(imageResult.url, function(image) {
            var dataURLcompressed = jicCompress(image, scope, imageResult.size);
            imageResult.compressed = {
              dataURL: dataURLcompressed,
              type: dataURLcompressed.match(/:(.+\/.+);/)[1]
            };
            callback(imageResult);
          });
        };

        var convertAndUploadImage = function(imageResult, htmlTarget, raw) {
          var filename =
            Math.random()
              .toString(36)
              .substring(7) + imageResult.file.type.replace("/", ".");
          var file = "";
          raw
            ? (file = imageResult.file)
            : (file = imageResult.compressed.dataURL);

          let fd = new FormData();

          if (!raw) {
            var dataURI = file;

            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(",")[0].indexOf("base64") >= 0)
              byteString = atob(dataURI.split(",")[1]);
            else byteString = unescape(dataURI.split(",")[1]);

            // separate out the mime component
            var mimeString = dataURI
              .split(",")[0]
              .split(":")[1]
              .split(";")[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }

            var fileToUpload = new Blob([ia], { type: mimeString });
            fd.append("file", fileToUpload, "blob.txt");
          } else {
            fd.append("file", file, "blob.txt");
          }

          if (scope.src == "themeImgUpload") {
            themes.uploadThemeImage(scope.tableId, fd).then(response => {
              $rootScope.$emit("themeImgUpload", response.data);
            });
          } else if (scope.src == "replaceThemeImgUpload") {
            themes
              .uploadReplacementImage(scope.tableId, fd)
              .then(response => {
                $rootScope.$emit("replaceThemeImgUpload", response.data);
              });
          } else if (scope.src == "firstWatermarkUpload") {
            themes.createWatermark(fd).then(response => {
              $rootScope.$emit("firstWatermarkUpload", response.data);
            });
          } else if (scope.src == "secondWatermarkUpload") {
            themes.createWatermark(fd).then(response => {
              $rootScope.$emit("secondWatermarkUpload", response.data);
            });
          }
        };

        element.bind("change", function(evt) {
          //when multiple always return an array of images
          if (attrs.multiple) {
            scope.image = [];
          }

          var files = evt.target.files;

          for (var i = 0; i < files.length; i++) {
            //create a result object for each file in files

            var imageResult = {
              file: files[i],
              size: files[i].size,
              url: URL.createObjectURL(files[i])
            };

            fileToDataURL(files[i]).then(function(dataURL) {
              imageResult.dataURL = dataURL;
            });

            const htmlTarget = attrs.id;

            if (imageResult.size > 1000000) {
              const acceptResize = confirm(
                "The DPS software will resize any image larger than 1mb. The resizing process will require more time the larger the image. (For example, a 1mb image will take roughly 3 second to resize using a slow internet connection and a 5mb image will take 6 seconds.) Select 'OK' if you would like to proceed, or select 'Cancel' to choose a different image."
              );
              if (acceptResize == true) {
                //resize image
                doResizing(imageResult, function(imageResult) {
                  convertAndUploadImage(imageResult, htmlTarget);
                });
              }
            } else {
              //no resizing
              convertAndUploadImage(imageResult, htmlTarget, true);
            }
          }
        });
      }
    };
  }
]);
