angular.module("app").service("clients", function($http, $q) {
  this.searchClients = function(payload) {
    var deferred = $q.defer();
    $http
      .post(api + "searchUsers", payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getClient = function(id) {
    var deferred = $q.defer();
    $http
      .get(api + `userDetails/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getAllEmails = function() {
    var deferred = $q.defer();
    $http
      .get(api + `usersEmailsAsCSV`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getAllCorporations = function() {
    var deferred = $q.defer();
    $http
      .get(api + `corporations`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.assignToCorp = function(id, payload) {
    var deferred = $q.defer();
    $http
      .patch(api + `assignFuneralHomeToCorporation/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateUser = function(id, payload) {
    var deferred = $q.defer();
    $http
      .patch(api + `users/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.archiveUser = function(id) {
    var deferred = $q.defer();
    $http
      .patch(api + `archiveUser/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.unarchiveUser = function(id) {
    var deferred = $q.defer();
    $http
      .patch(api + `unarchiveUser/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.archiveHome = function(id) {
    var deferred = $q.defer();
    $http
      .patch(api + `archiveFuneralHome/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.unarchiveHome = function(id) {
    var deferred = $q.defer();
    $http
      .patch(api + `unarchiveFuneralHome/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateHome = function(payload) {
    var deferred = $q.defer();
    $http
      .patch(api + "funeralHomes", payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.checkForHome = function(id) {
    const deferred = $q.defer();
    $http.get(api + `funeralHomes/${id}`).then(response => {
      this.home = response.data
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  };

  this.migrateHome = function(payload) {
    var deferred = $q.defer();
    $http
      .patch(api + 'migrateFuneralHome', payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };
});
