angular.module("app").service("corporateClients", function($http, $q) {

  this.searchClients = function(payload) {
    var deferred = $q.defer();
    $http
      .post(api + "searchCorporationUsers", payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.checkForHome = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `corporationFuneralHomes/${id}`)
      .then(response => {
        this.home = response.data;
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };
});
