(function() {
  "use strict";
  angular.module("app").directive("clients", clientsController);

  function clientsController($timeout) {
    return {
      templateUrl: "pages/clients/client-dashboard.html",
      controllerAs: "vm",
      controller: function(clients, $scope) {
        var vm = this;

        angular.extend(vm, {
          //Variables and Functions
          getAllEmails: getAllEmails,
          search: search,
          searchResponseTotal: null,
          searchFilter: "all",
          client: {},
          clients: [],
          loadClient: loadClient,
          closeDrawers: closeDrawers,
          showDrawer: [],
          searchTerms: [
            "User Name",
            "User Id",
            "User Email",
            "Corporation",
            "Funeral Home Name",
            "Funeral Home Id",
            "Funeral Home Location",
            "Not Enrolled"
          ],
          selectedSearchTerm: "User Name",
          archiveUser: archiveUser,
          unarchiveUser: unarchiveUser,
          archiveHome: archiveHome,
          unarchiveHome: unarchiveHome,
          enrollHome: enrollHome,
          unenrollHome: unenrollHome,
          migrateUser: migrateUser,
          migrateHome: migrateHome,
          getAllCorporations: getAllCorporations,
          selectedCorporation: '',
          assignCorp: assignCorp,
          showChangeModal: showChangeModal,
          allowCorpAssign: true
        });

        init()

        function init() {
          getAllCorporations()
        }

        function archiveUser(user_id) {
          clients.archiveUser(user_id).then(response => {
            clients.getClient(user_id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function unarchiveUser(user_id) {
          clients.unarchiveUser(user_id).then(response => {
            clients.getClient(user_id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function archiveHome(home_id) {
          clients.archiveHome(home_id).then(response => {
            clients.getClient(vm.client.user.id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function unarchiveHome(home_id) {
          clients.unarchiveHome(home_id).then(response => {
            clients.getClient(vm.client.user.id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function enrollHome(home_id) {
          const payload = {
            id: home_id,
            enrolled: true
          };
          clients.updateHome(payload).then(response => {
            vm.client.funeral_home.enrolled_at = response.data.enrolled_at;
          });
        }

        function unenrollHome(home_id) {
          const payload = {
            id: home_id,
            enrolled: false
          };
          clients.updateHome(payload).then(response => {
            vm.client.funeral_home.enrolled_at = response.data.enrolled_at;
          });
        }

        function migrateUser() {
          clients.checkForHome(vm.destinationHomeId).then(response => {
            if(response.status == 200){
              const migrateConfirm = confirm(`Are you sure you want to move user to the '${response.data.name}' home?`)
              if(migrateConfirm == true){
                clients
                  .updateUser(vm.client.user.id, {
                    funeral_home_id: vm.destinationHomeId
                  })
                  .then(response => {
                    clients
                      .searchClients({ user_id: vm.client.user.id })
                      .then(response => {
                        vm.clients = response.data.users;
                        vm.searchResponseTotal = response.data.total;
                        clients.getClient(vm.clients[0].id).then(response => {
                          vm.client = response.data;
                        });
                        $("#change-modal").modal("hide");
                      });
                  });
              } else {
                $("#change-modal").modal("hide");
              }
            } else {
              alert(response.data.message)
            }
          })
        }

        function migrateHome() {
          clients.checkForHome(vm.destinationHomeId).then(response => {
            if(response.status == 200){
              const migrateConfirm = confirm(`Are you sure you want to move user to the '${response.data.name}'`)
              if(migrateConfirm == true){
                const payload = {
                  current_home_id: vm.client.user.funeral_home_id,
                  new_home_id: vm.destinationHomeId
                }
                clients.migrateHome(payload).then(response => {
                  clients
                    .searchClients({ user_id: vm.client.user.id })
                    .then(response => {
                      vm.clients = response.data.users;
                      vm.searchResponseTotal = response.data.total;
                      clients.getClient(vm.clients[0].id).then(response => {
                        vm.client = response.data;
                      });
                      $("#change-modal").modal("hide");
                    });
                })
              } else {
                $("#change-modal").modal("hide");
              }
            } else {
              alert(response.data.message)
            }
          })
        }

        function showChangeModal() {
          $("#change-modal").modal("show");
        }

        function getAllEmails(){
          clients.getAllEmails().then(response => {
            var filename = "user_emails.csv";
            var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
          })
        }

        function search(page) {
          page ? vm.page = page : vm.page = 1
          vm.showDrawer = [];
          vm.clients = [];
          vm.client = null;
          vm.pages = []
          let searchPayload = {};

          if (vm.selectedSearchTerm == "Not Enrolled") {
            clients.searchClients({ not_enrolled: true }).then(response => {
              vm.clients = response.data.users;
              vm.searchResponseTotal = response.data.total;
            });
          } else {
            const searchKey = vm.selectedSearchTerm
              .toLowerCase()
              .split(" ")
              .join("_");
            searchPayload[searchKey] = vm.searchValue;
            vm.searchFilter == "archived"
              ? (searchPayload.archived = true)
              : (searchPayload.archived = false);
            vm.searchFilter == "inactive"
              ? (searchPayload.active = false)
              : null;
            searchPayload.page = page - 1 || 0
            clients.searchClients(searchPayload).then(response => {
              const range = Math.ceil(response.data.total / 20);
              for (let i = 1; i <= range; i++) {
                vm.pages.push(i);
              }
              vm.clients = response.data.users;
              vm.searchResponseTotal = response.data.total;
            });
          }
        }

        function loadClient(client, index) {
          clients.getClient(client.id).then(response => {
            vm.client = response.data;
            vm.allowCorpAssign = vm.client.user.type == 'user' ? true : false;
            if(vm.client.user.corporation_id){
              vm.selectedCorporation = vm.corporations.find(corp => corp.id == vm.client.user.corporation_id)
            } else {
              vm.selectedCorporation = null
            }
          });

          for (let i in vm.showDrawer) {
            if (i !== index) vm.showDrawer[i] = false;
          }
          vm.showDrawer[index] = !vm.showDrawer[index];
        }

        function getAllCorporations() {
          clients.getAllCorporations().then(response => {
            vm.corporations = response.data
            vm.corporations.unshift({name: "No Corporation"})
          })
        }

        function assignCorp(client, index) {
          clients.assignToCorp(vm.client.user.funeral_home_id, {corporation_id: vm.selectedCorporation.id || null})
            .then(response => {
              vm.clients[index].corporation = vm.selectedCorporation.name
              alert(`${client.name} and ${vm.client.funeral_home.name} have been assigned to ${vm.selectedCorporation.name}`)
            })
        }

        function closeDrawers() {
          for (let i in vm.showDrawer) {
            vm.showDrawer[i] = false;
          }
          vm.client = {};
        }

        //******** end *********//
      }
    };
  }
})();
