(function() {
  "use strict";
  angular.module("app").directive("themeEditor", themeEditorController);

  function themeEditorController(
    themes,
    $timeout,
    $routeParams,
    helpers,
    $rootScope,
    $document
  ) {
    return {
      templateUrl: "pages/themes/theme_editor/theme-editor.html",
      controllerAs: "vm",
      controller: function($scope) {
        const vm = this;

        angular.extend(vm, {
          tab: "info",
          theme: {},
          coloroptions: helpers.colorOptions,
          fonts: helpers.fonts,
          updateTheme: updateTheme,
          goToUpload: goToUpload,
          uploadThemeImage: uploadThemeImage,
          replaceProductThemeImage: replaceProductThemeImage,
          showWatermarkModal: showWatermarkModal,
          showEditImageModal: showEditImageModal,
          pickWatermarkImage: pickWatermarkImage,
          pickWatermarkSecondImage: pickWatermarkSecondImage,
          setTarget: setTarget,
          changeWatermarkOpacity: changeWatermarkOpacity,
          saveWatermarksAndExit: saveWatermarksAndExit,
          updateWatermarkPosition: updateWatermarkPosition,
          deleteWatermark: deleteWatermark,
          render: true,
          selectProduct: selectProduct,
          selectSize: selectSize,
          editReplacementImage: false,
          selectedFont: null,
          selectedCategory: null,
          selectedProduct: null,
          selectProduct: selectProduct,
          selectSize: selectSize,
          products: null,
          categories: null,
          showThemePositionModal: showThemePositionModal,
          adjustThemeSize: adjustThemeSize,
          showUploadText: true,
          themeFontColor: {},
          editImage: {},
          editImageProps: editImageProps,
          imageAdjustment: {},
          saveImageEdits: saveImageEdits,
          revertImage: revertImage,
          resetImageColor: resetImageColor,
          setImageRotation: setImageRotation,
          undoImageEditStates: [],
          undoImageEdits: undoImageEdits,
          updateThemeLayout: updateThemeLayout
        });

        init();

        function init() {
          getThemeById();
        }

        function getThemeById() {
          themes.getThemeById($routeParams.id).then(response => {
            vm.theme = response.data;
            getThemeCategoryById();
          });
        }

        function getThemeCategoryById() {
          return new Promise((resolve, reject) => {
            themes
              .getThemeCategoryById(vm.theme.theme_category_id)
              .then(response => {
                vm.category = response.data;
                resolve(vm.category.collection_id);
              });
          });
        }

        function getCollectionById() {
          themes.getCollectionById(vm.category.collection_id).then(response => {
            vm.collection = response.data;
          });
        }

        function getProducts(id) {
          themes.getProductsByCollectionId(id).then(response => {
            vm.products = response.data;
            selectProduct(vm.products[0]);
          });
        }

        function goToUpload() {
          if (vm.theme.name == "") {
            alert("Please choose a name for your theme");
          } else {
            updateTheme();
            vm.tab = "upload";
            applyWhenElementExists("#watermarkModal", setResizerDrag, 50);
            applyWhenElementExists("#watermarkModal", setDraggables, 50);
            applyWhenElementExists(
              "#themePositionModal",
              detectThemeReposition,
              50
            );
          }
        }

        function updateTheme() {
          for (let i in vm.theme) {
            if (vm.theme[i] == null) {
              delete vm.theme[i];
            }
          }
          const id = vm.theme.id;
          delete vm.theme.id;
          delete vm.theme.archived;
          themes.updateTheme(id, vm.theme).then(response => {
            vm.theme = response.data;
            if (!vm.category || !vm.collection || !vm.products) {
              getThemeCategoryById().then(collection_id => {
                getCollectionById(collection_id);
                getProducts(collection_id);
              });
            }
          });
        }

        function detectThemeReposition() {
          $("#themePositionModal").on("mouseup", function() {
            vm.render = false;
            let backgroundPosition = $("#themePositionModal #product").css(
              "background-position"
            );
            backgroundPosition = backgroundPosition.split(" ");
            const left = backgroundPosition[0];
            const top = backgroundPosition[1];
            vm.layout.background_left = left;
            vm.layout.background_top = top;
            renderPreview(vm.layout);
          });
        }

        function updateWatermarkPosition() {
          $("#watermarkModal #product #first").css({
            top: vm.firstWatermark.top,
            left: vm.firstWatermark.left
          });
          $("#watermarkModal #product #second").css({
            top: vm.secondWatermark.top,
            left: vm.secondWatermark.left
          });
        }

        function updatePositionFields() {
          if (vm.firstWatermark) {
            vm.firstWatermark.top = parseInt($("#first").css("top"));
            vm.firstWatermark.left = parseInt($("#first").css("left"));
          } else if (vm.secondWatermark) {
            vm.secondWatermark.top = parseInt($("#second").css("top"));
            vm.secondWatermark.left = parseInt($("#second").css("left"));
          }
        }

        function checkForFirstWatermark() {
          return new Promise((resolve, reject) => {
            if (vm.layout.first_watermark) {
              themes.getWatermark(vm.layout.first_watermark).then(response => {
                resolve(response.data);
              });
            } else {
              reject();
            }
          });
        }

        function checkForSecondWatermark() {
          return new Promise((resolve, reject) => {
            if (vm.layout.second_watermark) {
              themes.getWatermark(vm.layout.second_watermark).then(response => {
                resolve(response.data);
              });
            } else {
              reject();
            }
          });
        }

        function selectSize(layout) {
          vm.layout = layout;
          vm.selectedSize = layout.name;

          (async () => {
            await checkForFirstWatermark()
              .then(response => {
                vm.firstWatermark = response;
                vm.firstWatermark.opacity = vm.firstWatermark.opacity * 100;
              })
              .catch(() => {
                vm.firstWatermark = null;
              });

            await checkForSecondWatermark()
              .then(response => {
                vm.secondWatermark = response;
                vm.secondWatermark.opacity = vm.secondWatermark.opacity * 100;
              })
              .catch(() => {
                vm.secondWatermark = null;
              });

            renderPreview(layout);
          })();
        }

        function selectProduct(product) {
          vm.selectedProduct = product;
          vm.layout = null;
          themes.getLayouts(product.id, vm.theme.id).then(response => {
            console.log(response);
            vm.themeLayouts = response.data.sort((a, b) => {
              return (
                (a.replacement_image === null) -
                  (b.replacement_image === null) ||
                +(a.replacement_image > b.replacement_image) ||
                -(a.replacement_image < b.replacement_image)
              );
            });
            vm.layout = vm.themeLayouts[0];
            if (vm.theme.image) {
              (async () => {
                await checkForFirstWatermark()
                  .then(response => {
                    vm.firstWatermark = response;
                    vm.firstWatermark.opacity = vm.firstWatermark.opacity * 100;
                  })
                  .catch(() => {
                    vm.firstWatermark = null;
                  });

                await checkForSecondWatermark()
                  .then(response => {
                    vm.secondWatermark = response;
                    vm.secondWatermark.opacity =
                      vm.secondWatermark.opacity * 100;
                  })
                  .catch(() => {
                    vm.secondWatermark = null;
                  });

                renderPreview(vm.layout);
              })();
            } else {
              vm.firstWatermark = null;
              vm.secondWatermark = null;
            }
            vm.selectedSize = vm.layout.name;
          });
        }

        function renderPreview(layout, filename) {
          const image = filename ? filename : vm.theme.image;
          vm.layout = layout;
          const img = new Image();
          img.src = `${helpers.imgixTheme}${image}`;
          img.onload = function() {
            const imgRatio = this.width / this.height;
            const height = parseFloat(layout.theme_height) * 72;
            const width = parseFloat(layout.theme_width) * 72;
            const containRatio = width / height;

            if (isNaN(containRatio)) {
              // Do Nothing
            } else if (
              !vm.layout.background_fill ||
              (vm.layout.background_fill &&
                vm.layout.background_fill.indexOf("105") > -1 &&
                vm.render)
            ) {
              if (containRatio >= imgRatio) {
                layout.background_fill = "105% auto";
              } else {
                layout.background_fill = "auto 105%";
              }
              if (
                layout.background_top == null ||
                layout.background_left == null
              ) {
                layout.background_left = "50%";
                layout.background_top = "50%";
              }
            }
            const reduced_height =
              parseFloat(layout.theme_height) * 0.6 * 72 + "px";
            const reduced_width =
              parseFloat(layout.theme_width) * 0.6 * 72 + "px";
            let reduced_theme_position;
            if (
              vm.layout.background_top != "50%" &&
              vm.layout.background_left != "50%"
            ) {
              reduced_theme_position =
                parseInt(layout.background_left) * 0.6 +
                "px " +
                parseInt(layout.background_top) * 0.6 +
                "px";
            } else {
              reduced_theme_position = "50% 50%";
            }

            if (vm.layout.replacement_image) {
              $(".theme-editor #product").css(
                "background-image",
                `url(${helpers.imgixTheme}${vm.layout.replacement_image})`
              );
              $("#theme").css(
                "background-image",
                `url(${helpers.imgixTheme}${vm.layout.replacement_image})`
              );
            } else {
              $(".theme-editor #product").css(
                "background-image",
                `url(${helpers.imgixTheme}${vm.theme.image})`
              );
              $("#theme").css(
                "background-image",
                `url(${helpers.imgixTheme}${vm.theme.image})`
              );
            }
            renderWatermark();
            $(".theme-editor #product")
              .css("width", width + "px")
              .css("height", height + "px")
              .css("background-size", layout.background_fill)
              .css("background-repeat", "no-repeat")
              .css(
                "background-position",
                layout.background_left + " " + layout.background_top
              );
            $("#theme")
              .css("width", reduced_width)
              .css("height", reduced_height)
              .css("background-size", layout.background_fill)
              .css("background-repeat", "no-repeat")
              .css("background-position", reduced_theme_position);
          };
        }

        function renderWatermark() {
          if (vm.firstWatermark || vm.secondWatermark) {
            $("#theme img").remove();
            if (vm.firstWatermark) {
              const watermark = `<img src="${helpers.imgixTheme}${
                vm.firstWatermark.image
              }"
									style="position: absolute; width: ${vm.firstWatermark.width * 0.6}px;
									top: ${vm.firstWatermark.top * 0.6}px;
									left: ${vm.firstWatermark.left * 0.6}px;
									opacity: ${vm.firstWatermark.opacity / 100};">`;
              $("#theme").append(watermark);
              $("#first").css({
                width: vm.firstWatermark.width,
                top: vm.firstWatermark.top,
                left: vm.firstWatermark.left,
                opacity: vm.firstWatermark.opacity / 100
              });
            }
            if (vm.secondWatermark) {
              const watermark = `<img src="${helpers.imgixTheme}${
                vm.secondWatermark.image
              }"
								style="position: absolute; width: ${vm.secondWatermark.width * 0.6}px;
								top: ${vm.secondWatermark.top * 0.6}px;
								left: ${vm.secondWatermark.left * 0.6}px;
								opacity: ${vm.secondWatermark.opacity / 100};">`;
              $("#theme").append(watermark);
              $("#second").css({
                width: vm.secondWatermark.width,
                top: vm.secondWatermark.top,
                left: vm.secondWatermark.left,
                opacity: vm.secondWatermark.opacity / 100
              });
            }
          } else {
            $("#first").css({
              top: "0px",
              left: "0px",
              width: "",
              height: "",
              position: "absolute"
            });
            $("#first").removeClass("resizer-target deletor-target");
            $("#second").css({
              top: "0px",
              left: "0px",
              width: "",
              height: "",
              position: "absolute"
            });
            $("#second").removeClass("resizer-target deletor-target");
            $("#theme img").remove();
            if (vm.firstWatermark) {
              vm.firstWatermark = null;
            }
            if (vm.secondWatermark) {
              vm.secondWatermark = null;
            }
          }
        }

        function uploadThemeImage() {
          $timeout(function() {
            $("#theme_file")[0].click();
          });
        }

        function replaceProductThemeImage() {
          setTimeout(function() {
            document.getElementById("replace_theme_file").click();
          }, 10);
        }

        const themeUploadEventListener = $rootScope.$on(
          "themeImgUpload",
          (event, payload) => {
            vm.theme.image = payload;
            renderPreview(vm.layout, payload);
          }
        );

        const replacementImgEventListener = $rootScope.$on(
          "replaceThemeImgUpload",
          (event, payload) => {
            vm.layout.replacement_image = payload;
            renderPreview(vm.layout);
          }
        );

        const firstWatermarkEventListener = $rootScope.$on(
          "firstWatermarkUpload",
          (event, payload) => {
            if (vm.secondWatermark) {
              vm.secondWatermark.width = parseInt($("#second").css("width"));
              vm.secondWatermark.top = parseInt($("#second").css("top"));
              vm.secondWatermark.left = parseInt($("#second").css("left"));
            }
            vm.firstWatermark = payload;
            vm.firstWatermark.opacity = vm.firstWatermark.opacity * 100;
            themes
              .updateThemeLayout(vm.layout.id, { first_watermark: payload.id })
              .then(response => {
                vm.layout = response.data;
                renderPreview(vm.layout);
              });
          }
        );

        const secondWatermarkEventListener = $rootScope.$on(
          "secondWatermarkUpload",
          (event, payload) => {
            if (vm.firstWatermark) {
              vm.firstWatermark.width = parseInt($("#first").css("width"));
              vm.firstWatermark.top = parseInt($("#first").css("top"));
              vm.firstWatermark.left = parseInt($("#first").css("left"));
            }
            vm.secondWatermark = payload;
            vm.secondWatermark.opacity = vm.secondWatermark.opacity * 100;
            themes
              .updateThemeLayout(vm.layout.id, { second_watermark: payload.id })
              .then(response => {
                vm.layout = response.data;
                renderPreview(vm.layout);
              });
          }
        );

        $scope.$on("$destroy", () => {
          themeUploadEventListener();
          replacementImgEventListener();
          firstWatermarkEventListener();
          secondWatermarkEventListener();
        });

        function setTarget(element) {
          $("*").removeClass("resizer-target");
          $(element.target).addClass("resizer-target");
          $(element.target).addClass("deletor-target");
          positionResizer();
          positionDelete();
        }

        function showWatermarkModal() {
          $("#watermarkModal").modal("show");
        }

        function showThemePositionModal() {
          vm.themeSize = null;
          if (vm.layout.background_fill) {
            if (vm.layout.background_fill.split(" ")[0] != "auto") {
              vm.themeSize = parseInt(vm.layout.background_fill.split(" ")[0]);
            } else {
              vm.themeSize = parseInt(vm.layout.background_fill.split(" ")[1]);
            }
          }
          $("#themePositionModal").modal("show");
        }

        function adjustThemeSize() {
          if (vm.layout.background_fill) {
            if (vm.layout.background_fill.split(" ")[0] != "auto") {
              vm.layout.background_fill = vm.themeSize + "%" + " auto";
            } else {
              vm.layout.background_fill = "auto " + vm.themeSize + "%";
            }
          } else {
            vm.layout.background_left = $(".theme-editor #product")
              .css("background-position")
              .split(" ")[0];
            vm.layout.background_top = $(".theme-editor #product")
              .css("background-position")
              .split(" ")[1];
          }
          renderPreview(vm.layout);
        }

        function updateThemeLayout() {
          if (vm.layout.id) {
            const payload = {
              background_fill: vm.layout.background_fill,
              background_top: vm.layout.background_top,
              background_left: vm.layout.background_left,
              available: vm.layout.available
            };
            themes.updateThemeLayout(vm.layout.id, payload).then(response => {
              vm.layout = response.data;
            });
          }
        }

        //Image editor
        function saveImageEdits() {
          $timeout(() => {
            if (vm.editReplacementImage) {
              themes
                .updateThemeLayout(vm.layout.id, {
                  replacement_image: vm.editImage.url
                })
                .then(response => {
                  vm.layout = response.data;
                  renderPreview(vm.layout);
                  $("#editImageModal").modal("hide");
                  renderPreview(vm.layout);
                });
            } else {
              vm.theme.image = vm.editImage.url;
              const id = vm.theme.id;
              delete vm.theme.id;
              themes
                .updateTheme(id, { image: vm.theme.image })
                .then(response => {
                  vm.theme = response.data;
                  $("#editImageModal").modal("hide");
                  renderPreview(vm.layout);
                });
            }
          }, 100);
        }

        function undoImageEdits() {
          let backgroundFileName =
            vm.undoImageEditStates[vm.undoImageEditStates.length - 1];
          //reset vm.imageAdjustment to 0
          for (key in vm.imageAdjustment) {
            vm.imageAdjustment[key] = 0;
          }

          //find each key in the previous query string and set it on the model
          for (var key in vm.imageAdjustment) {
            if (backgroundFileName.indexOf(key) != -1) {
              if (key === "mono") {
                vm.imageAdjustment[key] =
                  "#" +
                  backgroundFileName.substr(
                    backgroundFileName.indexOf(key) + (key.length + 1),
                    6
                  );
              }
              if (key === "blend") {
                var q = backgroundFileName.match(/(&blend).*&/g)[0];
                q = q
                  .substring(q.indexOf("="))
                  .split("&")
                  .shift();
                if (q.length === 7) {
                  vm.imageAdjustment[key] = "#" + q.substr(1);
                } else {
                  vm.imageAdjustment[key] = q.substr(1);
                }
              } else if (key === "bm") {
                var q = backgroundFileName.match(/(&bm).*&/g)[0];
                q = q
                  .substring(q.indexOf("="))
                  .split("&")
                  .shift();
                vm.imageAdjustment[key] = q.substr(1);
              } else {
                vm.imageAdjustment[key] = parseInt(
                  backgroundFileName.substr(
                    backgroundFileName.indexOf(key) + (key.length + 1),
                    4
                  )
                );
              }
            }
          }
          vm.editImage.url = backgroundFileName;
          vm.undoImageEditStates.pop();
        }

        function editImageProps() {
          vm.undoImageEditStates.push(vm.editImage.url);
          // get image url so the query can be built
          const imageFile = vm.editImage.url
            .split("&")[0]
            .split("/")
            .pop();
          let query = "";
          if (imageFile.indexOf("?") === -1) query = "?";

          let path = imageFile + query;

          // find each key that has a value not 0 and add it to the query string
          for (let key in vm.imageAdjustment) {
            if (vm.imageAdjustment[key] != 0) {
              if (key === "mono") {
                var keystring =
                  "&" + key + "=" + vm.imageAdjustment.mono.substring(1) + "&";
                path += keystring;
              } else if (key === "blend") {
                if (vm.imageAdjustment.blend) {
                  if (vm.imageAdjustment.blend.indexOf("#") === 0) {
                    keystring =
                      "&blend=" + vm.imageAdjustment.blend.substring(1) + "&";
                  } else if (vm.imageAdjustment.blend.indexOf("http") != -1) {
                    keystring =
                      "&blend=" +
                      vm.imageAdjustment.blend +
                      "&bm=" +
                      vm.imageAdjustment.bm +
                      "&balph=" +
                      vm.imageAdjustment.balph +
                      "&bf=crop&bc=top,left&bw=" +
                      vm.imageAdjustment.bw +
                      "&bh=" +
                      vm.imageAdjustment.bh;
                  }
                }
                path += keystring;
              } else if (
                key != "bw" &&
                key != "bh" &&
                key != "bm" &&
                key != "balph"
              ) {
                var keystring = "&" + key + "=" + vm.imageAdjustment[key];
                path += keystring;
              }
            }
          }
          vm.editImage.url = path;
        }

        function revertImage() {
          //reset all query selectors and then run props
          for (let key in vm.imageAdjustment) {
            vm.imageAdjustment[key] = 0;
          }
          editImageProps();
        }

        function resetImageColor(key) {
          // remove hex from color selector
          vm.imageAdjustment[key] = 0;
          editImageProps();
        }

        function setImageRotation(value) {
          vm.imageAdjustment.or = value;
          editImageProps();
        }

        function showEditImageModal(replacement_image) {
          let width, height;

          let backgroundFileName;
          replacement_image
            ? ((backgroundFileName = replacement_image),
              (vm.editReplacementImage = true))
            : ((backgroundFileName = vm.theme.image),
              (vm.editReplacementImage = false));

          const newImage = new Image();
          newImage.src = `${helpers.imgixTheme}${backgroundFileName}`;

          vm.imageAdjustment = {
            vib: 0,
            hue: 0,
            bri: 0,
            con: 0,
            high: 0,
            sat: 0,
            shad: 0,
            sharp: 0,
            exp: 0,
            gam: 0,
            blur: 0,
            sepia: 0,
            blend: 0,
            mono: 0,
            htn: 0,
            or: 0
          };

          //determine if there are already settings in the query and set vm.imageAdjustment to those values
          for (let key in vm.imageAdjustment) {
            if (backgroundFileName.indexOf(key) != -1) {
              if (key === "mono") {
                vm.imageAdjustment[key] =
                  "#" +
                  backgroundFileName.substr(
                    backgroundFileName.indexOf(key) + (key.length + 1),
                    6
                  );
              }
              if (key === "blend") {
                let q = backgroundFileName.match(/(&blend).*&/g)[0];
                q = q
                  .substring(q.indexOf("="))
                  .split("&")
                  .shift();
                if (q.length === 7) {
                  vm.imageAdjustment[key] = "#" + q.substr(1);
                } else {
                  vm.imageAdjustment[key] = q.substr(1);
                }
              } else if (key === "bm") {
                let q = backgroundFileName.match(/(&bm).*&/g)[0];
                q = q
                  .substring(q.indexOf("="))
                  .split("&")
                  .shift();
                vm.imageAdjustment[key] = q.substr(1);
              } else {
                vm.imageAdjustment[key] = parseInt(
                  backgroundFileName.substr(
                    backgroundFileName.indexOf(key) + (key.length + 1),
                    4
                  )
                );
              }
            }
          }
          $(newImage).load(function() {
            width = `${newImage.width}px`;
            height = `${newImage.height}px`;

            vm.editImage = {
              url: backgroundFileName,
              width: width,
              height: height,
              modalHeight: Math.max(
                parseInt(height) + 100,
                parseInt(width) + 100
              ),
              modalWidth: Math.max(
                parseInt(height) + 400,
                parseInt(width) + 400
              ),
              wrapperWidth:
                Math.max(parseInt(height) + 400, parseInt(width) + 400) - 250
            };

            $("#editImageModal").modal("show");
            $timeout(function() {
              $("#edit_image").load(function() {});
            }, 500);
          });
        }

        function changeWatermarkOpacity(watermark) {
          if (vm.firstWatermark && watermark == "first") {
            $("#watermarkModal #first").css({
              opacity: vm.firstWatermark.opacity / 100
            });
          }
          if (vm.secondWatermark && watermark == "second") {
            $("#watermarkModal #second").css({
              opacity: vm.secondWatermark.opacity / 100
            });
          }
        }

        function applyWhenElementExists(selector, myFunction, intervalTime) {
          var interval = setInterval(() => {
            if (jQuery(selector).length > 0) {
              myFunction();
              clearInterval(interval);
            }
          }, intervalTime);
        }

        function setResizerDrag() {
          $("#watermarkModal").on("mousedown", ".resizer", function($event) {
            let startWidth, startHeight, initialMouseX, initialMouseY;

            startWidth = parseInt($(".resizer-target").css("width"));
            startHeight = parseInt($(".resizer-target").css("height"));
            initialMouseX = $event.clientX;
            initialMouseY = $event.clientY;

            $document.bind("mousemove", mousemove);
            $document.bind("mouseup", mouseup);

            function mousemove($event) {
              const dx = $event.clientX - initialMouseX;
              const dy = $event.clientY - initialMouseY;

              const ratio = startHeight / startWidth;

              if ($(".resizer-target").data("type") == "image") {
                $(".resizer-target").css({
                  width: startWidth + dx + "px",
                  height: startHeight + dx * ratio + "px"
                });
              } else {
                $(".resizer-target").css({
                  width: startWidth + dx + "px",
                  height: startHeight + dy + "px"
                });
              }
              updatePositionFields();
              positionResizer();
              positionDelete();

              return false;
            }

            function mouseup() {
              $document.unbind("mousemove", mousemove);
              $document.unbind("mouseup", mouseup);
            }
          });
        }

        function setDraggables() {
          $("#watermarkModal").on("mousedown", ".draggable", function($event) {
            let startX, startY, initialMouseX, initialMouseY;

            const target = $event.target;

            $("#watermarkModal img").removeClass(
              "resizer-target deletor-target"
            );
            $(target).addClass("resizer-target");
            $(target).addClass("deletor-target");
            $(target).css({
              position: "absolute",
              cursor: "pointer"
            });

            startX = $(target).prop("offsetLeft");
            startY = $(target).prop("offsetTop");
            initialMouseX = $event.clientX;
            initialMouseY = $event.clientY;

            $document.bind("mousemove", mousemove);
            $document.bind("mouseup", mouseup);

            function mousemove($event) {
              const dx = $event.clientX - initialMouseX;
              const dy = $event.clientY - initialMouseY;
              positionResizer();
              positionDelete();
              updatePositionFields();
              $(target).css({
                top: startY + dy + "px",
                left: startX + dx + "px"
              });
              return false;
            }

            function mouseup() {
              $document.unbind("mousemove", mousemove);
              $document.unbind("mouseup", mouseup);
            }
          });
        }

        function positionResizer() {
          if ($(".resizer-target").length) {
            $(".resizer").css("visibility", "visible");
          } else {
            $(".resizer").css("visibility", "hidden");
          }
          const target = $(".resizer-target");
          const top =
            parseInt(target.css("height")) + parseInt(target.css("top"));
          const left =
            parseInt(target.css("width")) + parseInt(target.css("left"));
          $("#watermarkModal .resizer").css({
            top: top,
            left: left
          });
        }

        function positionDelete() {
          if ($(".deletor-target").length) {
            $(".deletor").css("visibility", "visible");
          } else {
            $(".deletor").css("visibility", "hidden");
          }
          const target = $(".deletor-target");
          const top = parseInt(target.css("top")) - 20;
          const right =
            parseInt(target.css("width")) + parseInt(target.css("right"));
          $("#watermarkModal .deletor").css({
            top: top,
            right: right
          });
        }

        function deleteWatermark() {
          if ($(".deletor-target").is("#first")) {
            saveSecondWatermark();
            themes.archiveWatermark(vm.firstWatermark.id).then(response => {
              themes
                .updateThemeLayout(vm.layout.id, { first_watermark: null })
                .then(response => {
                  vm.layout = response.data;
                  renderPreview(vm.layout);
                });
            });
            vm.firstWatermark = null;
            vm.layout.first_watermark = null;
            $("#first").css({
              top: "0px",
              left: "0px",
              width: "0px",
              position: "absolute"
            });
            $("#first").removeClass("resizer-target deletor-target");
          } else if ($(".deletor-target").is("#second")) {
            saveFirstWatermark();
            themes.archiveWatermark(vm.secondWatermark.id).then(response => {
              themes
                .updateThemeLayout(vm.layout.id, { second_watermark: null })
                .then(response => {
                  vm.layout = response.data;
                  renderPreview(vm.layout);
                });
            });
            vm.secondWatermark = null;
            vm.layout.second_watermark = null;
            $("#second").css({
              top: "0px",
              left: "0px",
              width: "0px",
              position: "absolute"
            });
            $("#second").removeClass("resizer-target deletor-target");
          }

          positionDelete();
          positionResizer();
        }

        function pickWatermarkSecondImage() {
          setTimeout(function() {
            document.getElementById("second_watermark_file").click();
          }, 10);
        }

        function pickWatermarkImage() {
          setTimeout(function() {
            document.getElementById("first_watermark_file").click();
          }, 10);
        }

        function saveFirstWatermark() {
          $(".deletor").css("visibility", "hidden");
          $(".resizer").css("visibility", "hidden");
          $("#first").removeClass("resizer-target deletor-target");
          $("#second").removeClass("resizer-target deletor-target");
          setFirstWatermarkValues().then(response => {
            vm.firstWatermark = response;
            vm.firstWatermark.opacity = vm.firstWatermark.opacity * 100;
          });
        }

        function saveSecondWatermark() {
          $(".deletor").css("visibility", "hidden");
          $(".resizer").css("visibility", "hidden");
          $("#first").removeClass("resizer-target deletor-target");
          $("#second").removeClass("resizer-target deletor-target");
          setSecondWatermarkValues().then(response => {
            vm.secondWatermark = response;
            vm.secondWatermark.opacity = vm.secondWatermark.opacity * 100;
          });
        }

        async function saveWatermarksAndExit() {
          $(".deletor").css("visibility", "hidden");
          $(".resizer").css("visibility", "hidden");
          $("#first").removeClass("resizer-target deletor-target");
          $("#second").removeClass("resizer-target deletor-target");

          await setFirstWatermarkValues()
            .then(response => {
              vm.firstWatermark = response;
              vm.firstWatermark.opacity = vm.firstWatermark.opacity * 100;
            })
            .catch(() => {
              // No First Watermark
            });

          await setSecondWatermarkValues()
            .then(response => {
              vm.secondWatermark = response;
              vm.secondWatermark.opacity = vm.secondWatermark.opacity * 100;
            })
            .catch(() => {
              // No Second Watermark
            });

          renderPreview(vm.layout);
          $("#watermarkModal").modal("hide");
        }

        function setFirstWatermarkValues() {
          return new Promise((resolve, reject) => {
            if (vm.firstWatermark.id) {
              vm.firstWatermark.width = parseInt($("#first").css("width"));
              vm.firstWatermark.top = parseInt($("#first").css("top"));
              vm.firstWatermark.left = parseInt($("#first").css("left"));
              vm.firstWatermark.opacity = vm.firstWatermark.opacity / 100;
              const id = vm.firstWatermark.id;
              delete vm.firstWatermark.id;
              delete vm.firstWatermark.archived;
              themes.updateWatermark(id, vm.firstWatermark).then(response => {
                resolve(response.data);
              });
            } else {
              reject();
            }
          });
        }

        function setSecondWatermarkValues() {
          return new Promise((resolve, reject) => {
            if (vm.secondWatermark.id) {
              vm.secondWatermark.width = parseInt($("#second").css("width"));
              vm.secondWatermark.top = parseInt($("#second").css("top"));
              vm.secondWatermark.left = parseInt($("#second").css("left"));
              vm.secondWatermark.opacity = vm.secondWatermark.opacity / 100;
              const id = vm.secondWatermark.id;
              delete vm.secondWatermark.id;
              delete vm.secondWatermark.archived;

              themes.updateWatermark(id, vm.secondWatermark).then(response => {
                resolve(response.data);
              });
            } else {
              reject();
            }
          });
        }

        //******** end *********//
      }
    };
  }
})();
