(function() {
  'use strict'
  angular.module('app').directive('siteheader', headerController)

  function headerController(user) {
    return {

      templateUrl: 'pages/header/header.html',
      controllerAs: 'hvm',
      controller: function($location) {
        var vm = this

        angular.extend(vm, {
					logout: logout,
          superAdmin: false,
          corporationAdmin: false,
        })

        userType()

        function userType() {
          user.userInfo && user.userInfo.type ?
            (vm.userInfo = user.userInfo, generateMenu()) :
            user.get().then(response => (vm.userInfo = response.data, generateMenu()))
        }

        function generateMenu () {
          vm.superAdmin = vm.userInfo.type == 'superAdmin' ? true : false
          vm.corporationAdmin = vm.userInfo.type == 'corporationAdmin' ? true : false
        }

				function logout() {
					user.logout()
					window.location.href = '/'
				}

        //******** end *********//
      }
    }
  }

})();
