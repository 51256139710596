const themeAnalytics = () => {
  angular.module("app").directive("themeAnalytics", function() {
    return {
      restrict: 'A',
      templateUrl: "pages/analytics/theme-analytics.html"
    }
  });
};

const productAnalytics = () => {
  angular.module("app").directive("productAnalytics", function() {
    return {
      restrict: 'A',
      templateUrl: "pages/analytics/product-analytics.html"
    }
  });
};

themeAnalytics()
productAnalytics()
