angular.module('app').directive('file', function() {
	return {
		restrict: 'AE',
		scope: {
			file: '@'
		},
		link: function(scope, el, attrs) {
			el.bind('change', function(event) {
				var files = event.target.files;
				var file = files[0];
				scope.file = file;
				scope.$parent.file = file;
				scope.$apply();
			});
		}
	};
});

angular.module('app').directive('resizable', function() {
	return {
		restrict: 'A',
		link: function(scope, elm, attrs) {
			var height = elm.height()
			elm.css({
				borderRadius: '50%',
				width: height
			})
		}
	}
})

angular.module('app').directive('draggable', ['$document', function($document) {
	return {
		restrict: 'A',
		link: function(scope, elm, attrs) {
			var startX, startY, initialMouseX, initialMouseY;
			elm.css({
				position: 'absolute',
				cursor: 'pointer'
			});

			elm.bind('mousedown', function($event) {
				startX = elm.prop('offsetLeft');
				startY = elm.prop('offsetTop');
				initialMouseX = $event.clientX;
				initialMouseY = $event.clientY;
				elm.css({
					outline: '1px dashed gray'
				})
				$document.bind('mousemove', mousemove);
				$document.bind('mouseup', mouseup);
				//return false;
			});

			function mousemove($event) {
				var dx = $event.clientX - initialMouseX;
				var dy = $event.clientY - initialMouseY;
				elm.css({
					top: startY + dy + 'px',
					left: startX + dx + 'px'
				});
				return false;
			}

			function mouseup() {
				$document.unbind('mousemove', mousemove);
				$document.unbind('mouseup', mouseup);
				elm.css({
					outline: 'none'
				})
			}
		}
	};
}])

angular.module('app').directive('hidden', ['$document', function($document){
	return {
		restrict: 'A',
		link: function(scope, elm, attrs) {
			elm.css({
				display: 'none'
			})
		}
	}
}])

angular.module('app').directive('clickOutside', function ($document) {
    return {
       restrict: 'A',
       scope: {
           clickOutside: '&'
       },
       link: function (scope, el, attr) {

           $document.on('click', function (e) {
               if (el !== e.target && !el[0].contains(e.target)) {
                    scope.$apply(function () {
                        scope.$eval(scope.clickOutside);
                    });
                }
           });
       }
    }
});

angular.module('app').directive('bgdraggable', ['$document', function($document) {
	return {
		restrict: 'A',
		link: function(scope, elm, attrs) {
			var startX, startY, initialMouseX, initialMouseY;
			elm.css('cursor','grab')

			elm.bind('mousedown', function($event) {
				startX = elm.css('background-position').split(' ')[0];
				startY = elm.css('background-position').split(' ')[1];
				initialMouseX = $event.clientX;
				initialMouseY = $event.clientY;
				elm.css({
					outline: '1px dashed gray'
				})
				$document.bind('mousemove', mousemove);
				$document.bind('mouseup', mouseup);
				//return false;
			});

			function mousemove($event) {
				var dx = $event.clientX - initialMouseX;
				var dy = $event.clientY - initialMouseY;
				var top = parseInt(startY) + dy
				var left = parseInt(startX) + dx
				var position = left + 'px ' + top + 'px'
				elm.css('background-position', position);
				return false;
			}

			function mouseup() {
				$document.unbind('mousemove', mousemove);
				$document.unbind('mouseup', mouseup);
				elm.css({
					outline: 'none'
				})
			}
		}
	};
}]);
