angular.module("app", [
  "ngRoute",
  "ngAnimate",
  "ui.bootstrap",
  "slick",
  "ui.checkbox",
  "angularSpectrumColorpicker"
]);

angular.module("app").config(function($routeProvider, $locationProvider) {
  $locationProvider.html5Mode({
    enabled: true
  });

  const superAdmin = ($location, user) => {
    return new Promise((resolve, reject) => {
      if (user.userInfo) {
        if (user.userInfo.type == "superAdmin") {
          resolve();
        } else {
          reject();
          $location.url("/corporateClients");
        }
      } else {
        user.get().then(response => {
          if (response.data.type == "superAdmin") {
            resolve();
          } else {
            reject();
            $location.url("/corporateClients");
          }
        });
      }
    });
  };

  const corporationAdmin = ($location, user) => {
    return new Promise((resolve, reject) => {
      if (user.userInfo) {
        if (user.userInfo.type == "corporationAdmin") {
          resolve();
        } else {
          reject();
          $location.url("/corporateClients");
        }
      } else {
        user.get().then(response => {
          if (response.data.type == "corporationAdmin") {
            resolve();
          } else {
            reject();
            $location.url("/clients");
          }
        });
      }
    });
  };

  $routeProvider
    .when("/login", {
      template: "<login></login>"
    })
    .when("/analytics", {
      template: "<analytics></analytics>",
      resolve: { superAdmin: superAdmin }
    })
    .when("/clients", {
      template: "<clients></clients>",
      resolve: { superAdmin: superAdmin }
    })
    .when("/corporateClients", {
      template: "<corporate-clients></corporate-clients>",
      resolve: { corporationAdmin: corporationAdmin }
    })
    .when("/themes", {
      template: "<themes-dashboard></themes-dashboard>",
      resolve: { superAdmin: superAdmin }
    })
    .when("/category/:id", {
      template: "<theme-category-dashboard></theme-category-dashboard>",
      resolve: { superAdmin: superAdmin }
    })
    .when("/themeEditor/:id", {
      template: "<theme-editor></theme-editor>",
      resolve: { superAdmin: superAdmin }
    })
    .when("/themeEditor", {
      template: "<theme-editor></theme-editor>",
      resolve: { superAdmin: superAdmin }
    })
    .otherwise({
      redirectTo: "/login"
    });
});

angular.module("app").run(function($http, $rootScope, $location, user) {
  if (localStorage.getItem("token")) {
    $http.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
  }
});

let api;
if (window.location.hostname == "localhost") {
  // api = "//localhost:8080/";
  // api = "https://dps-int-test.herokuapp.com/";
  api = "https://dps-prod.herokuapp.com/";
} else if (
  window.location.href.indexOf("admin-dev.directorsprintsuite.com") >= 0
) {
  api = `https://dps-develop.herokuapp.com/`;
} else if (
  window.location.href.indexOf("admin-testing.directorsprintsuite.com") >= 0
) {
  api = `https://dps-int-test.herokuapp.com/`;
} else if (window.location.href.indexOf("dps-admin-testing") >= 0) {
  api = `https://dps-int-test.herokuapp.com/`;
} else {
  api = `https://dps-prod.herokuapp.com/`;
}
console.log(
  `%c api = ${api}`,
  "color: gray; font-weight: bold; font-style: italic"
);

jQuery.fn.outerHTML = function(s) {
  return s
    ? this.before(s).remove()
    : jQuery("<p>")
        .append(this.eq(0).clone())
        .html();
};
