angular.module('app').service('user', function($http, $q) {

  this.userInfo

  this.resetAppValues = function(){
		$http.defaults.headers.common["x-auth-token"] = null;
		localStorage.removeItem("token");
    this.userInfo = null
	}

	this.login = function(payload) {
		var deferred = $q.defer();
		this.resetAppValues()
		$http.post(api + "signin", payload).then(response => {
      this.userInfo = response.data
			localStorage.setItem("token", response.data.token);
			$http.defaults.headers.common["x-auth-token"] = response.data.token;
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.logout = function() {
		this.resetAppValues()
	};

	this.get = function() {
		var deferred = $q.defer();
		$http.get(api + "user").then(response => {
			this.userInfo = response.data;
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};
})
