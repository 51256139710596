(function() {
  'use strict'
  angular.module('app').directive('loader', loaderController)

  function loaderController() {
    return {
      templateUrl: 'partials/loader.html',
      controllerAs: 'loader',
      controller: function() {


        //******** end *********//
      }
    }
  }

})();
