const analyticsController = () => {
  'use strict'
  angular.module("app").directive("analytics", function(analytics) {
    return {
      templateUrl: "pages/analytics/analytics.html",
      controllerAs: "vm",
      controller: function() {
        var vm = this

        angular.extend(vm, {
          //Variables and Functions
          setTab: setTab
        })

        getThemesAnalytics()

        function getThemesAnalytics() {
          vm.tab = 'themes'
          analytics.themeUsage ?
            vm.themeUsageAll = analytics.themeUsage :
            analytics.getThemesAnalytics().then(response => {
              vm.themeUsageAll = response.data
              vm.themeUsageAll.usage.map(({name, themes}) => {
                name == 'Precious Memories' ? vm.themeUsagePM = themes :
                name == 'Reflections of Life' ? vm.themeUsageROL = themes :
                vm.themeUsageLE = themes
              })
            })
        }

        function getProductsAnalytics() {
          vm.tab = 'products'
          analytics.getProductsAnalytics().then(response => {
            vm.productUsageAll = response.data
            vm.productUsageAll.usage.map(({name, products}) => {
              name == 'Precious Memories' ? vm.productUsagePM = products :
              name == 'Reflections of Life' ? vm.productUsageROL = products :
              vm.productUsageLE = products
            })
          })
        }

        function setTab(tab) {
          tab == 'products' ? getProductsAnalytics() : getThemesAnalytics();
        }

        //******** end *********//
      }
    }
  });
};

analyticsController()
