(() => {
  angular.module("app").directive("infoTab", function() {
    return {
      restrict: 'A',
      templateUrl: "pages/themes/theme_editor/info-tab.html"
    }
  });
})();

(() => {
  angular.module("app").directive("uploadTab", function() {
    return {
      restrict: 'A',
      templateUrl: "pages/themes/theme_editor/upload-tab.html"
    }
  });
})();

(() => {
  angular.module("app").directive("productList", () => {
    return {
      restrict: 'A',
      templateUrl: "pages/themes/theme_editor/product-list.html"
    }
  });
})();

(() => {
  angular.module("app").directive("themePreview", () => {
    return {
      restrict: 'A',
      templateUrl: "pages/themes/theme_editor/theme-preview.html"
    }
  });
})();

(() => {
  angular.module("app").directive("imageActionBar", () => {
    return {
      restrict: 'A',
      templateUrl: "pages/themes/theme_editor/image-action-bar.html"
    }
  });
})();
