(function() {
  "use strict";
  angular.module("app").directive("corporateClients", clientsController);

  function clientsController($timeout) {
    return {
      templateUrl: "pages/corporate_clients/corporate-client-dashboard.html",
      controllerAs: "vm",
      controller: function(clients, corporateClients) {
        var vm = this;

        angular.extend(vm, {
          //Variables and Functions
          getAllEmails: getAllEmails,
          search: search,
          searchResponseTotal: null,
          searchFilter: "all",
          client: {},
          clients: [],
          loadClient: loadClient,
          closeDrawers: closeDrawers,
          showDrawer: [],
          searchTerms: [
            "User Name",
            "User Id",
            "User Email",
            "Funeral Home Name",
            "Funeral Home Id",
            "Funeral Home Location"
          ],
          selectedSearchTerm: "User Name",
          archiveUser: archiveUser,
          unarchiveUser: unarchiveUser,
          archiveHome: archiveHome,
          unarchiveHome: unarchiveHome,
          migrateUser: migrateUser,
          migrateHome: migrateHome,
          showChangeModal: showChangeModal
        });

        function archiveUser(user_id) {
          clients.archiveUser(user_id).then(response => {
            clients.getClient(user_id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function unarchiveUser(user_id) {
          clients.unarchiveUser(user_id).then(response => {
            clients.getClient(user_id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function archiveHome(home_id) {
          clients.archiveHome(home_id).then(response => {
            clients.getClient(vm.client.user.id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function unarchiveHome(home_id) {
          clients.unarchiveHome(home_id).then(response => {
            clients.getClient(vm.client.user.id).then(response => {
              vm.client = response.data;
            });
          });
        }

        function migrateUser() {
          corporateClients.checkForHome(vm.destinationHomeId).then(response => {
            if (response.status == 200) {
              const migrateConfirm = confirm(
                `Are you sure you want to move user to the '${
                  response.data.name
                }' home?`
              );
              if (migrateConfirm == true) {
                clients
                  .updateUser(vm.client.user.id, {
                    funeral_home_id: vm.destinationHomeId
                  })
                  .then(response => {
                    corporateClients
                      .searchClients({ user_id: vm.client.user.id })
                      .then(response => {
                        vm.clients = response.data.users;
                        vm.searchResponseTotal = response.data.total;
                        clients
                          .getClient(vm.clients[0].id)
                          .then(response => {
                            vm.client = response.data;
                          });
                        $("#change-modal").modal("hide");
                      });
                  });
              } else {
                $("#change-modal").modal("hide");
              }
            } else {
              alert(response.data.message);
            }
          });
        }

        function migrateHome() {
          corporateClients.checkForHome(vm.destinationHomeId).then(response => {
            if (response.status == 200) {
              const migrateConfirm = confirm(
                `Are you sure you want to move user to the '${
                  response.data.name
                }'`
              );
              if (migrateConfirm == true) {
                const payload = {
                  current_home_id: vm.client.user.funeral_home_id,
                  new_home_id: vm.destinationHomeId
                };
                clients.migrateHome(payload).then(response => {
                  corporateClients
                    .searchClients({ user_id: vm.client.user.id })
                    .then(response => {
                      vm.clients = response.data.users;
                      vm.searchResponseTotal = response.data.total;
                      clients
                        .getClient(vm.clients[0].id)
                        .then(response => {
                          vm.client = response.data;
                        });
                      $("#change-modal").modal("hide");
                    });
                });
              } else {
                $("#change-modal").modal("hide");
              }
            } else {
              alert(response.data.message);
            }
          });
        }

        function showChangeModal() {
          $("#change-modal").modal("show");
        }

        function getAllEmails() {
          clients.getAllEmails().then(response => {
            var filename = "user_emails.csv";
            var blob = new Blob([response.data], {
              type: "text/csv;charset=utf-8;"
            });
            if (navigator.msSaveBlob) {
              // IE 10+
              navigator.msSaveBlob(blob, filename);
            } else {
              var link = document.createElement("a");
              if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          });
        }

        function search(page) {
          page ? (vm.page = page) : (vm.page = 1);
          vm.showDrawer = [];
          vm.clients = [];
          vm.client = null;
          vm.pages = [];
          let searchPayload = {};

          if (vm.selectedSearchTerm == "Not Enrolled") {
            corporateClients
              .searchClients({ not_enrolled: true })
              .then(response => {
                vm.clients = response.data.users;
                vm.searchResponseTotal = response.data.total;
              });
          } else {
            const searchKey = vm.selectedSearchTerm
              .toLowerCase()
              .split(" ")
              .join("_");
            searchPayload[searchKey] = vm.searchValue;
            vm.searchFilter == "archived"
              ? (searchPayload.archived = true)
              : (searchPayload.archived = false);
            vm.searchFilter == "inactive"
              ? (searchPayload.active = false)
              : null;
            searchPayload.page = page - 1 || 0;
            corporateClients.searchClients(searchPayload).then(response => {
              const range = Math.ceil(response.data.total / 20);
              for (let i = 1; i <= range; i++) {
                vm.pages.push(i);
              }
              vm.clients = response.data.users;
              vm.searchResponseTotal = response.data.total;
            });
          }
        }

        function loadClient(client, index) {
          clients.getClient(client.id).then(response => {
            vm.client = response.data;
          });

          for (let i in vm.showDrawer) {
            if (i !== index) vm.showDrawer[i] = false;
          }
          vm.showDrawer[index] = !vm.showDrawer[index];
        }

        function closeDrawers() {
          for (let i in vm.showDrawer) {
            vm.showDrawer[i] = false;
          }
          vm.client = {};
        }

        //******** end *********//
      }
    };
  }
})();
